import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { choices } from "../Constants";
import okkIc from "../images/okkIc.png";
import { handlePostAPI } from "../hooks/handlePostAPI";
import { cleanObjects, emptyLocalStorage } from "../util/util";
import { UserContext } from "../Context/UserContext";
import { successToast } from "../util/toasts";

const UserChoice = () => {
  const {
    localCode,
    localJoinEmail,
    localUserChoices,
    setLocalUserChoices,
    localUserPlugging,
    setLocalInviteCode,
  } = useContext(UserContext);

  let navigate = useNavigate();

  const [userChoices, setUserChoices] = useState(localUserChoices || []);

  const selectedTab = (name) => {
    setUserChoices((prev) =>
      prev.includes(name) ? prev.filter((e) => e !== name) : [...prev, name]
    );
  };

  const isChoiceSelected = (condition) =>
    condition ? "checking-span-active-bg" : "checking-span-bg";

  const isListSelected = (condition) =>
    condition ? "active-list-item-bg" : "list-item-bg";

  const isImageSelected = (condition) =>
    condition ? "checking-span-active-img" : "checking-span-img";

  const handleUserPlugging = async () => {
    if (userChoices.length > 0) {
      setLocalUserChoices(userChoices);

      const apiParameter = {
        code: localCode,
        join_email: localJoinEmail,
        plugged_in_as: localUserPlugging,
        identified_as: userChoices,
      };

      const res = await handlePostAPI("/info/", cleanObjects(apiParameter));

      if (res.error) {
        console.error(res.error || "Invalid Parameter");
      } else {
        successToast(res.message || "Your account is created");
        setLocalInviteCode(res.invite_code);
        emptyLocalStorage();
        navigate("/mint-page");
      }
    } else {
      alert("Please select at least one");
    }
  };

  return (
    <>
      <fieldset className="wizard-fieldset">
        <div className="grad"></div>
        <div className="grad1"></div>
        <div className="container">
          <div className="step-wrapper">
            <div className="wallet-address-box user-choice-box  innerStep3 ">
              <div className="wall-bx">
                <h2>
                  How do you <span>Identify</span>?{" "}
                </h2>

                <div className="identify-checklist ">
                  <h3> InTandem is committed to maintaining diversity.</h3>
                  <h3 className="userChoiceTextPadding">
                    If comfortable, please let us know how you identify so we
                    can better understand and manage the diversity within our
                    community. The information from this question will not be
                    used for marketing or sold.
                  </h3>
                  <h3 className="fontweight">
                    I self identify as belonging to a community that is
                    underrepresented in startups due to my...
                  </h3>{" "}
                  {/* <h3>I self identify as underrepresented due to my</h3> */}
                  <ul className="row">
                    {choices.map((e) => (
                      <div className="col-12 col-md-6 offset-md-3" key={e.id}>
                        <li
                          key={e.id}
                          onClick={() => selectedTab(e.value)}
                          className={`list-item ${isListSelected(
                            userChoices.includes(e.value)
                          )}`}
                        >
                          <input type="radio" name="" />
                          <div className="checking">
                            <span
                              className={`checking-span  ${isChoiceSelected(
                                userChoices.includes(e.value)
                              )} `}
                            >
                              <img
                                className={isImageSelected(
                                  userChoices.includes(e.value)
                                )}
                                src={okkIc}
                                alt=""
                              />
                            </span>
                          </div>
                          <div className="checkHeadline">
                            <h4>{e.choice}</h4>
                          </div>
                        </li>
                      </div>
                    ))}
                  </ul>
                  <div className="form-wizard">
                    <button
                      onClick={handleUserPlugging}
                      className="form-wizard-next-btn btn-main"
                    >
                      NEXT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default UserChoice;
