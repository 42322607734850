import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../util/util";
import logo from "../images/welcomeLogo.svg";
import { useContext } from "react";
import { UserContext } from "../Context/UserContext";

const Welcome = () => {
  const { localName } = useContext(UserContext);
  return (
    <>
      <fieldset className="wizard-fieldset show">
        <div className="grad"></div>
        <div className="grad1"></div>
        <div className="container">
          <div className="inner-wizard-box">
            {localName ? (
              <h2 className="text-left">
                Hello{" "}
                <span className="pro-fontfamily">
                  {capitalizeFirstLetter(localName)} !
                </span>
              </h2>
            ) : null}

            <div className="welcome-heading-section">
              <h2>Welcome to </h2>
              <img
                src={logo}
                alt="Intandem Logo"
                className="ms-md-3 intandem-logo"
              />
            </div>

            <div className="inner-content-box">
              <div>
                <div className="inner-content-dt">
                  <p>Thank you for being a part of the InTandem beta!</p>
                  <p>
                    By joining InTandem, you will be joining a passionate,
                    curious and dedicated group of people coming together to
                    help minority founded startups scale, connect with like
                    minded marketers, entrepreneurs and investors, and get
                    hands-on experience DAOs, NFTs, blockchain tokens and other
                    Web3 technology (no experience or knowledge needed).
                  </p>
                  <p>
                    InTandem is an invite-only community, only accessible to
                    those with an{" "}
                    <span className="welcomePageSpan">InTandem NFT</span>. We’ll
                    ask you a few questions to get you started, and will guide
                    you through the process of getting your{" "}
                    <span className="welcomePageSpan">NFT</span> and onboarding
                    to the community.
                  </p>{" "}
                  <p>
                    We will also have email content and virtual AMA (ask me
                    anything) sessions you can attend to ask questions and learn
                    about the technology powering this first of its kind
                    community.
                  </p>
                  {/* <p>
                    Thank you for being a part of the beta! We are excited to
                    build this community with you. Your feedback will be
                    critical for helping us get ready for the public launch in
                    Q1 2023. Please make sure to capture all feedback and send
                    to{" "}
                    <a href="mailto:Thoughts@InTandemDAO.com">
                      Thoughts@InTandemDAO.com.
                    </a>{" "}
                    That includes feedback on this registration process.
                  </p>

                  <p>
                    We will officially launch the beta Nov 1st, and you will be
                    able to start interacting with the other members. During the
                    beta you will be interacting with{" "}
                    <span className="welcomePageSpan">founders, advisors </span>{" "}
                    and supporters to help minority founders scale their
                    companies, while connecting with like minded people and
                    getting hands-on experience with a DAO (no experience with
                    DAOs, NFTs, or Web3 needed!).
                  </p>

                  <p>
                    We’ll ask a couple questions to get you signed up, and help
                    you mint your <span className="welcomePageSpan">NFT</span>{" "}
                    (no worries if you don’t have any experience with NFTs, we
                    will guide you through the process)
                  </p> */}
                </div>

                <div className="form-wizard mt-5">
                  <Link
                    to="/get-started"
                    className="form-wizard-next-btn  float-right "
                  >
                    Next
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default Welcome;
