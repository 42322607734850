import React, { useState, createContext, useEffect, useRef } from "react";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";

import {
  contractAddress,
  infura_RPC_URL_ETH_Mainnet,
  MAIN_NETWORK,
  networkParams,
  NFT_MINT_PRICE,
} from "../Constant/contractConstant";
import { abi } from "../Constant/abi";

import { useLocalStorage } from "../hooks/useLocalStorage";
import { errorToast, loadingToast, successToast } from "../util/toasts";
import toast from "react-hot-toast";
import { handlePostAPI } from "../hooks/handlePostAPI";
import { toHex, waitFunction } from "../util/util";

export const UserContext = createContext();

const ContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentAccount, setCurrentAccount] = useState("");
  const [contract, setContract] = useState(null);
  const [network, setNetwork] = useState(MAIN_NETWORK);

  const [chainId, setChainId] = useState(["0x1", 1, "1", "0x13881"]);

  const [localCode, setLocalCode] = useLocalStorage("code", "");
  const [localName, setLocalName] = useLocalStorage("name", "");
  const [localUserRole, setLocalUserRole] = useLocalStorage("UserRole", "");
  const [localInviteCode, setLocalInviteCode] = useLocalStorage(
    "InviteCode",
    ""
  );
  const [localJoinEmail, setLocalJoinEmail] = useLocalStorage("joinEmail", "");
  const [localUserChoices, setLocalUserChoices] = useLocalStorage(
    "UserChoices",
    ""
  );
  const [localUserPlugging, setLocalUserPlugging] = useLocalStorage(
    "UserPlugging",
    ""
  );
  const walletProvider = useRef();
  const toastId = useRef();

  const web3 = new Web3(infura_RPC_URL_ETH_Mainnet);

  const getEthereumContract = async () => {
    const contract = await new web3.eth.Contract(abi, contractAddress);
    setContract(contract);
  };

  //Connect to metamask
  const connectToMetamask = async () => {
    if (window.ethereum === undefined) {
      return false;
    }
    const { ethereum } = window;
    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      walletProvider.current = window.ethereum;
      if (accounts.length !== 0) {
        setCurrentAccount(accounts[0]);
        successToast("Wallet is connected.");
        await validateChain();
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  //connect To WalletConnect
  const connectToWalletConnect = async () => {
    try {
      const walletConnector = new WalletConnectProvider({
        rpc: {
          1: infura_RPC_URL_ETH_Mainnet,
        },
        qrcode: true,
        pollingInterval: 15000,
      });
      //Show QR Code
      walletProvider.current = walletConnector;
      const account = await walletConnector.enable();
      setCurrentAccount(account[0]);
      successToast("Wallet is connected.");
      await validateChain();
    } catch (error) {
      console.log("error", error);
    }
  };

  const saveTransactionOnBackend = async ({
    code = localCode,
    transaction_hash,
    is_redeemed = false,
    redeemer_wallet = currentAccount,
    redeemer_email = localJoinEmail,
  }) => {
    const response = await handlePostAPI("/save-transaction/", {
      code,
      transaction_hash,
      is_redeemed,
      redeemer_email,
      redeemer_wallet,
    });

    return response;
  };

  // const balanceOf = async (walletAddress) => {
  //   const data = await contract.methods.balanceOf(walletAddress, 1).call();
  //   console.log(data);
  //   return;
  // };

  // TODO: Make readable code
  const mintToken = async (otp, proof) => {
    setIsLoading(true);
    try {
      await validateChain();
      const data = await contract.methods.claim(1, 1, otp, proof).encodeABI();

      let parms = {
        from: currentAccount,
        to: contractAddress,
        data: data,
        value: Number(NFT_MINT_PRICE).toString(16),
      };
      toastId.current = loadingToast("Your transaction in progress");
      window.we = walletProvider.current;
      const transaction_hash = await walletProvider.current.request({
        method: "eth_sendTransaction",
        params: [parms],
      });
      // Sending this transaction to backend
      const response = await saveTransactionOnBackend({
        transaction_hash,
      });

      if (response.message === "success") {
        waitFunction(10000); // Waiting for 10 Sec to execute next line
        const receipt = await web3.eth.getTransactionReceipt(transaction_hash);
        console.log("receipt--->", receipt);
        return Promise.resolve("success");
      } else {
        errorToast(response.error || "");
        return Promise.reject(response);
      }
    } catch (error) {
      console.log("error ==>", error);
      window.er = error;
      if (error.message === "execution reverted") {
        errorToast("You already minted from this wallet");
      } else {
        errorToast(error.message || "Your transaction is failed");
      }
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 2000);
    }
  };

  const switchNetwork = async () => {
    try {
      await walletProvider.current.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(network) }],
      });
      successToast("Your chain id switched to Polygon Mainnet");
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await walletProvider.current.request({
            method: "wallet_addEthereumChain",
            params: [networkParams[toHex(network)]],
          });
          successToast("Your chain id switched to Polygon Mainnet");
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  // validate chain
  const validateChain = async () => {
    const fetchChainId = walletProvider.current.chainId;
    console.log(fetchChainId);
    if (!chainId.includes(fetchChainId)) {
      setIsLoading(false);
      throw new Error("Please switch to Ethereum Mainnet");
      // switchNetwork();
    }
  };

  useEffect(() => {
    getEthereumContract();
    // handleAutoConnectWallet();
  }, []);

  return (
    <>
      <UserContext.Provider
        value={{
          currentAccount,
          connectToMetamask,
          connectToWalletConnect,
          isLoading,
          setIsLoading,
          mintToken,

          // Local Storage Variable
          localCode,
          setLocalCode,
          localName,
          setLocalName,
          localUserRole,
          setLocalUserRole,
          localJoinEmail,
          setLocalJoinEmail,
          localUserChoices,
          setLocalUserChoices,
          localUserPlugging,
          setLocalUserPlugging,
          localInviteCode,
          setLocalInviteCode,
          // Local Storage Variable
        }}
      >
        {children}
      </UserContext.Provider>
    </>
  );
};

export default ContextProvider;
