import logo from "../images/supporter-icon.svg";

const SupporterThankyou = () => {
  return (
    <>
      <fieldset className="wizard-fieldset">
        <div className="grad"></div>
        <div className="grad1"></div>
        <div className="container">
          <div className="step-wrapper">
            <div className="inner-wizard-box">
              <h2>
                <span>You’re in.</span>
              </h2>
            </div>
            <div className="addressBox mt-5">
              <img
                src={logo}
                addressBox
                width="20%"
                height="100%"
                className="mx-auto"
                alt="Intandem supporter"
              />
              <p className="mt-4">
                We couldn’t be more excited to build InTandem alongside you.
                We’re in the final stages of getting the DAO ready to launch,
                and we’ll send you an email when we’re ready to show the world
                what we’ve created!
              </p>
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default SupporterThankyou;
