import toast from "react-hot-toast";

export const successToast = (message) => toast.success(message);
export const errorToast = (message) => toast.error(message);
export const loadingToast = (message) => toast.loading(message);

export const promiseToast = (myPromise) =>
  toast.promise(myPromise, {
    loading: "Please wait while your transaction is processing !!!",
    success: "Your Transaction is successfully made",
    error: "You Transaction was failed",
  });

// TODO: Fix Designing for this custom toast
export const customToast = (e) => {
  e.preventDefault();
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } d-flex bg-white p-3 justify-content-between`}
    >
      <div className="mr-4">
        <h5>Loading</h5>
        <p>Lorem items </p>
      </div>

      <button
        onClick={() => toast.dismiss(t.id)}
        className=" ml-5 btn btn-primary"
      >
        Close
      </button>
    </div>
  ));
};
