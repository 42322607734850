import { useContext } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { UserContext } from "../Context/UserContext";
import stripLogo from "../images/strip.svg";
import WalletImg from "../images/walletConnect.svg";
import earthIcon from "../images/earthIcon.svg";
import line from "../images/Line.svg";

import { useNavigate } from "react-router-dom";

const ConnectWalletModal = (props) => {
  let navigate = useNavigate();
  const {
    connectToMetamask,
    connectToWalletConnect,
    setIsLoading,
    localUserRole,
  } = useContext(UserContext);
  const { showModal, setShowModal } = props;

  const handleModalClose = () => {
    setIsLoading(false);
    setShowModal(false);
  };

  const connectWalletConnect = async () => {
    await connectToWalletConnect();
    handleModalClose();
  };

  const connectMetamask = async () => {
    await connectToMetamask();
    handleModalClose();
  };

  

  const handleEmailFlow = () => {
    setIsLoading(false);
    if (localUserRole) {
      navigate("/payment");
    } else {
      navigate("/failure");
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <div className="model">
          <ModalBody className="d-flex  justify-content-around">
            <button
              onClick={connectMetamask}
              className="border-0 text-center modal-btn"
            >
              <img
                src={earthIcon}
                className="modal-img2"
                alt="Wallet Connect"
              />
              <p>Browser Wallets</p>
            </button>
            <img src={line} className="line ms-3" alt="Wallet Connect" />
            <button
              onClick={connectWalletConnect}
              className="border-0 text-center modal-btn"
            >
              <img
                src={WalletImg}
                className="modal-img1"
                alt="Wallet Connect"
              />
              <p> Wallet Connect</p>
            </button>
            <img src={line} className="line" alt="Wallet Connect" />
            <button
              onClick={handleEmailFlow}
              className="border-0 text-center modal-btn"
            >
              <img
                src={stripLogo}
                alt="Metamask Sign in"
                className="modal-img2"
              />
              <p>Pay with stripe</p>
            </button>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

export default ConnectWalletModal;

//testing with mint token
