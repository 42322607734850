import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import ConnectWalletModal from "./ConnectWalletModal";
import { UserContext } from "../Context/UserContext";
import { googleSignButtonURL } from "../api_routes";
const GetStarted = () => {
  const { localCode } = useContext(UserContext);

  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <fieldset className="wizard-fieldset">
        <div className="grad"></div>
        <div className="grad1"></div>
        <div className="container">
          <div className="step-wrapper">
            <div className="inner-wizard-box">
              <h2>Let’s get started.</h2>
            </div>

            <div className="addressBox  addressBox1">
              <div className="mx-auto">
                <p>
                  First, we need your email! We promise to never spam you.
                  <br />
                  Please sign in with the same email that received an invite code.
                </p>
                <div className="btnBox">
                  <a
                    href={googleSignButtonURL + localCode}
                    className="main-btn mx-md-3"
                  >
                    Continue with GMAIL
                  </a>

                  <Link to="/preferred-email" className="main-btn mt-4 mt-md-0">
                    Continue with Other Email
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <ConnectWalletModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};
export default GetStarted;









