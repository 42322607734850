export const select = [
  {
    id: 1,
    name: "ADVISOR",
    value: "ADVISOR",
    para: "Advisors contribute knowledge, host sessions, make connections, and mentor startups all while increasing their knowledge and expanding their network.  ",
  },
  // {
  //   id: 2,
  //   name: "FOUNDER",
  //   value: "FOUNDER",
  //   para: "(participate in learning sessions, meet other founders, obtain mentorship)",
  // },
  {
    id: 3,
    name: "GENERAL SUPPORTER",
    value: "GENERAL_SUPPORTER",
    para: "General Supporters Help raise money for the DAO, contribute to support the DAO",
  },

  
];

export const choices = [
  {
    id: 1,
    choice: "GENDER",
    value: "Gender",
  },
  {
    id: 2,
    choice: "RACE/ETHNICITY",
    value: "Race_Ethnicity",
  },
  {
    id: 3,
    choice: "SEXUAL ORIENTATION",
    value: "Sexual_Orientation",
  },
  {
    id: 4,
    choice: "OTHER",
    value: "Other",
  },
  {
    id: 5,
    choice: "PREFER NOT TO SAY",
    value: "Prefer_Not_To_Say",
  },
  {
    id: 6,
    choice: "NONE OF THE ABOVE",
    value: "None_Of_The_Above",
  },
];
