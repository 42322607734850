import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../Context/UserContext";

const PaymentConfirmationCheck = () => {
  let navigate = useNavigate();
  const { localUserRole } = useContext(UserContext);
  const { state } = useLocation();

  const { stripe_payment_check } = state || {
    stripe_payment_check: false,
  };

  useEffect(() => {
    if (stripe_payment_check === "success" && localUserRole === "VIP") {
      navigate("/invite-code");
    } else if (
      stripe_payment_check === "success" &&
      localUserRole === "SUPPORTER"
    ) {
      navigate("/supporter-complete");
    }
  }, [stripe_payment_check]);

  return (
    <div className="cover-full-dom">
      <div className="spinner-border " role="status"></div>
    </div>
  );
};

export default PaymentConfirmationCheck;
