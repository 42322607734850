import { useContext } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Context/UserContext";
import { handlePostAPI } from "../hooks/handlePostAPI";

const PreferredEmail = () => {
  const { localCode, localJoinEmail, setLocalJoinEmail, localUserRole } =
    useContext(UserContext);

  let navigate = useNavigate();

  const [joinEmail, setJoinEmail] = useState(localJoinEmail || "");

  const handleJoinEmail = async () => {
    const regEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (regEx.test(joinEmail)) {
      const res = await handlePostAPI("/verify-email/", {
        login_code: localCode,
        join_email: joinEmail,
      });
      if (res.error) {
        console.log(res.error);
        navigate(`/user-plugging/failure/${res.error}`);
        return false;
      } else {
        if (localUserRole === "SUPPORTER") {
          setLocalJoinEmail(joinEmail);
          navigate(
            `/user-plugging/success/${localCode}/?exists=${res.exists}&email=${joinEmail}`
          );
        } else {
          navigate(
            `/user-plugging/success/${localCode}/?exists=${res.exists}&email=${localJoinEmail}`
          );
        }
      }
    } else {
      alert("Please Provide an valid  Email");
    }
  };

  return (
    <>
      <fieldset className="wizard-fieldset">
        <div className="grad"></div>
        <div className="grad1"></div>
        <div className="container">
          <div className="step-wrapper">
            <div className="wallet-address-box user-choice-box innerStep3 ">
              <div className="wall-bx">
                <h2>What’s your email?</h2>
                <div className="code-frm">
                  <div className="formBox">
                    <input
                      type="email"
                      required
                      placeholder="Enter your email..."
                      className="form-control text-center"
                      value={joinEmail}
                      onChange={(e) => setJoinEmail(e.target.value)}
                    />
                    <span>INVALID EMAIL !</span>
                  </div>
                  <div className="form-wizard">
                    <button
                      onClick={handleJoinEmail}
                      className="form-wizard-next-btn btn-main"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default PreferredEmail;
