import { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../Context/UserContext";

import { Button } from "react-bootstrap";
import INTAN_DAO_PASS from "../images/IntandemDaoPass.png";

import ConnectWalletModal from "./ConnectWalletModal";
import { handlePostAPI } from "../hooks/handlePostAPI";

import { cleanObjects } from "../util/util";
import { dotLoader } from "../util/loader";
import { useEffect } from "react";
import { API_BASE_URL } from "../api_routes";
import { errorToast } from "../util/toasts";

const MintPage = () => {
  let navigate = useNavigate();
  const {
    isLoading,
    setIsLoading,
    localCode,
    localJoinEmail,
    currentAccount,
    mintToken,
    localUserRole,
    setLocalInviteCode,
  } = useContext(UserContext);

  const { state } = useLocation();

  const [paymentStatus, setPaymentStatus] = useState(null);

  const { stripe_payment_check } = state || {
    stripe_payment_check: false,
  };

  const fetchAPI = async (url) => {
    const response = await fetch(API_BASE_URL + url);
    if (response.status >= 200 && response.status <= 299) {
      const jsonResponse = await response.json();
      console.log(jsonResponse);
      setPaymentStatus(jsonResponse.payment_status);
      // TODO:
      setLocalInviteCode(jsonResponse.invite_code || "");
    } else {
      // Handle errors
      errorToast("");
      console.log(response.status, response.statusText);
    }
  };

  useEffect(() => {
    fetchAPI(`/info?email=${localJoinEmail}&login_code=${localCode}`);
  }, []);

  // If Stripe Payment is made
  if (paymentStatus === 1 && localUserRole === "VIP") {
    navigate("/invite-code");
  } else if (paymentStatus === 1 && localUserRole === "SUPPORTER") {
    navigate("/supporter-complete");
    setIsLoading(false);
  }

  const [showModal, setShowModal] = useState(false);

  const handleConnectModal = () => {
    setShowModal(true);
    setIsLoading(true);
  };

  const handleNftMint = async () => {
    setIsLoading(true);
    const apiParameter = {
      code: localCode,
      wallet: currentAccount,
      email: localJoinEmail,
    };
    const response = await handlePostAPI(
      "/get-coupon/",
      cleanObjects(apiParameter)
    );
    if (response.error) {
      console.warn("Mint Failed");
    } else {
      const res = await mintToken(response.otp, response.proof);

      if (res === "success" && localUserRole === "VIP") {
        navigate("/invite-code");
        setIsLoading(false);
      } else if (res === "success" && localUserRole === "SUPPORTER") {
        navigate("/supporter-complete");
        setIsLoading(false);
      }
    }
  };

  const paymentCheck = (function () {
    var executed = false;
    return function () {
      if (!executed) {
        executed = true;
        // Function
        if (stripe_payment_check === "failure") {
          errorToast("Your Payment Failed. Please try again");
        }
      }
    };
  })();

  useEffect(() => {
    paymentCheck();
  }, [stripe_payment_check]);

  if (paymentStatus) dotLoader();

  return (
    <>
      <fieldset className="wizard-fieldset show">
        <div className="grad"></div>
        <div className="grad1"></div>
        <div className="mint-container">
          <div className="container inner-wizard-box">
            <div className="infoBox mintBox ">
              <div className="row  positionRelative ">
                <div className="col-12 col-lg-4 mt-1   ">
                  <div className="positionFixed p-xl-5 z-50">
                    <h2>
                      It’s time to join!
                      <br />
                      <span>MINT YOUR FREE NFT!</span>
                    </h2>
                    <img
                      className="infoImg mt-lg-4 "
                      alt="Intandem NFT"
                      src={INTAN_DAO_PASS}
                    />
                  </div>
                </div>
                <div className="col-12 offset-lg-1 col-lg-6 d-flex justify-center align-items-center mb-5 my-xl-5 py-xl-5 z-100">
                  <div className=" mx-3 mt-3  ">
                    <p>
                      InTandem is an invite-only community, only accessible to
                      those with an InTandem NFT.
                    </p>
                    <p>
                      The NFT is not only your membership card, but the sale of
                      the NFTs is how we are self-funding the community. All
                      funds generated will be used to support community
                      activities, content, experiences and operations. Your
                      InTandem NFT is also an ASSET, and as this community grows
                      and generates value to members (founders, advisors, and
                      supporters), the NFT you purchase today can go up in value
                      - and can be sold for USDs. Your NFT will also start
                      generating tokens as soon as you purchase it - these
                      tokens will be the currency of the DAO, and can be
                      redeemed for special access and earned through DAO
                      participation and learning and will be tradable on an open
                      market.
                      <br />
                      You can learn more about how the NFTs and tokens can
                      create monetary value for you as you engage with the
                      community.
                    </p>

                    <p>
                      By joining the beta, you will get a few unique benefits
                      including;
                    </p>
                    <ul>
                      <li>
                        Helping us craft this first of its kind community with
                        your feedback and ideas
                      </li>
                      <li>
                        An InTandem NFT with a rare “beta” trait - increasing
                        the value of your NFT
                      </li>
                      <li>A 30%+ discount on your NFT</li>
                    </ul>
                    <p>
                      Minority founders (and up to four staff members) receive
                      complimentary NFTs and access to the community.
                    </p>

                    <p>
                      To get your NFT, you will need to “mint” it. If you
                      already have a digital wallet, you can use our direct
                      connection with MetaMask or use WalletConnect to connect
                      any number of digital wallets. If you don’t already have a
                      digital wallet, no worries you can use a credit card!
                    </p>

                    <p>
                      And we will be providing content and virtual AMA sessions
                      around
                    </p>
                    <p className="ms-4 mt-n2">
                      <ol>
                        <li>What an NFT is</li>
                        <li>
                          How your NFT is critical to your membership experience
                        </li>
                        <li>
                          How to create a “digital wallet” to hold and use your
                          NFT
                        </li>
                        <li>Why InTandem is a “DAO”</li>
                        <li>General context on Web3</li>
                      </ol>
                    </p>

                    <div className="mintBtnContainer">
                      {currentAccount ? (
                        <Button onClick={handleNftMint} className="radial-btn ">
                          {isLoading ? dotLoader() : " MINT NOW"}
                        </Button>
                      ) : (
                        <Button
                          onClick={handleConnectModal}
                          className="radial-btn"
                        >
                          {isLoading ? dotLoader() : " CONNECT TO MINT"}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <ConnectWalletModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default MintPage;
