import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import okkIc from "../images/okkIc.png";
import { select } from "../Constants";
import { emptyLocalStorage } from "../util/util";
import { UserContext } from "../Context/UserContext";

const UserPlug = () => {
  let navigate = useNavigate();
  const {
    localCode,
    localUserPlugging,
    setLocalUserPlugging,
    setLocalJoinEmail,
  } = useContext(UserContext);
  const [searchParams] = useSearchParams();

  /* 
  Will get 2 properties after the google sign up 
  queryParameters :{
    exists: false,  -> It is a fresh account have to go through whole process
    email: google signed email
  }
  */
  const queryParameters = Object.fromEntries([...searchParams]);

  let { status, successCode } = useParams();

  const [userPlugging, setUserPlugging] = useState(localUserPlugging || []);

  // If user is not verified will redirect to failure page
  useEffect(() => {
    setLocalJoinEmail(queryParameters?.email);

    if (status !== "success") {
      navigate("/failure", { replace: true });
      emptyLocalStorage();
    } else if (status === "success" && localCode !== successCode) {
      console.warn("Code is invalid");
      navigate("/failure", { replace: true });
    } else if (queryParameters.exists.toLowerCase() !== "false") {
      navigate("/mint-page", { replace: true });
    }
  }, [status, successCode, queryParameters]);

  const selectedTab = (name) => {
    setUserPlugging((prev) =>
      prev.includes(name) ? prev.filter((e) => e !== name) : [...prev, name]
    );
  };
  const isIdentifySelected = (condition) =>
    condition ? "checking-span-active-bg" : "checking-span-bg";

  const isListSelected = (condition) =>
    condition ? "active-list-item-bg" : "list-item-bg";

  // Next page
  const handleUserPlugging = () => {
    if (userPlugging.length > 0) {
      setLocalUserPlugging(userPlugging);
      navigate("/user-choice");
    } else {
      alert("Please select at least one");
    }
  };

  return (
    <>
      <fieldset className="wizard-fieldset">
        <div className="grad"></div>
        <div className="grad1"></div>
        <div className="container">
          <div className="step-wrapper">
            <div className="wallet-address-box user-choice-box innerStep3">
              <div className="wall-bx">
                <h2>
                  How do you see yourself <br />
                  <span>plugging in</span>?
                </h2>

                <div className="plugging-checklist">
                  <h3>You can pick more than one. </h3>

                  <ul>
                    {select.map((e) => (
                      <li
                        key={e.id}
                        onClick={() => selectedTab(e.value)}
                        className={`list-item ${isListSelected(
                          userPlugging.includes(e.value)
                        )}`}
                      >
                        <input type="radio" name="" />
                        <div className="checking">
                          <span
                            className={`checking-span ${isIdentifySelected(
                              userPlugging.includes(e.value)
                            )}  `}
                          >
                            <img
                              className={isIdentifySelected(
                                userPlugging.includes(e.value)
                              )}
                              src={okkIc}
                              alt=""
                            />
                          </span>
                        </div>
                        <div className="checkHeadline">
                          <h4>{e.name}</h4>
                          <p>{e.para}</p>
                        </div>
                      </li>
                    ))}
                  </ul>

                  <div className="form-wizard mt-4">
                    <button
                      onClick={handleUserPlugging}
                      className="form-wizard-next-btn btn-main"
                    >
                      NEXT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default UserPlug;
