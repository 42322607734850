const Failure = () => {
  return (
    <fieldset className="wizard-fieldset show">
      <div className="grad"></div>
      <div className="grad1"></div>
      <div className="container">
        <div className="inner-wizard-box">
          <h2>Sign Up Failed</h2>
          <div className="inner-content-dt user-choice-box">
            <p >
              Please sign-up with valid code and email to create your profile on
              the intandem
            </p>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default Failure;
