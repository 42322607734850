export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function isEmpty(value) {
  if (
    value &&
    value !== "undefined" &&
    value !== "null" &&
    value !== "" &&
    typeof value !== undefined
  ) {
    return false;
  }
  return true;
}

export function isAuthenticated() {
  const code = localStorage.getItem("code");
  if (!isEmpty(code)) {
    return true;
  }
  return false;
}

export function cleanObjects(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

export function emptyLocalStorage() {
  window.localStorage.removeItem("name");
  window.localStorage.removeItem("walletAddress");
  window.localStorage.removeItem("prefEmail");
  window.localStorage.removeItem("UserPlugging");
  window.localStorage.removeItem("UserChoices");
}

export function cleanEntryCouponCode() {
  window.localStorage.removeItem("code");
}

export function waitFunction(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};
