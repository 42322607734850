import { useContext } from "react";
import { UserContext } from "../Context/UserContext";
import copy from "copy-to-clipboard";
import { successToast } from "../util/toasts";

const VipInviteCode = () => {
  const { localInviteCode } = useContext(UserContext);

  const copyToClipboard = (value) => {
    if (value) {
      copy(value);
      successToast(`Copied to clipboard`);
    }
  };
  return (
    <>
      <fieldset className="wizard-fieldset">
        <div className="grad"></div>
        <div className="grad1"></div>
        <div className="homepage-hero">
          <div className="container">
            <div className="step-wrapper">
              <div className="wallet-address-box final-step-wrp">
                <div className="inner-wizard-box">
                  {/* <h2>
                    Thank you for being a part of <br /> the
                    <span> beta community!</span>
                  </h2> */}
                  <h2>WELCOME TO THE DAO!</h2>
                </div>
                <div className="wall-bx">
                  <p>
                    Thank you for being a part of the beta community! <br /> As
                    a beta member you can invite up to 5 additional people you
                    think would be interested and benefit from this community.
                    We’ve sent you an email with more instructions!
                  </p>

                  <p className="wallBxBoldClass">
                    Note: we plan to include no more than 500 members in the
                    beta community. If someone you invite is not able to join
                    because we have reached our max, you will be able to invite
                    them at the discounted rate for the post beta launch
                  </p>

                  {/* <div className="text-center infoBox">
                    <a
                      href="https://intandem.paperform.co/"
                      className="radial-btn"
                    >
                      Invite Your Network
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="your-code-wrp">
            <div className="container">
              {/* <h2>YOUR INVITE CODE</h2>

              <div className="codeBox">
                <input
                  onClick={() => copyToClipboard(localInviteCode)}
                  type="text"
                  value={localInviteCode}
                  className="form-control pb-2"
                  readOnly
                />
                <p className="copy-text mt-2">Click to copy to clipboard</p>
              </div> */}

              <div className="text-center infoBox">
                <a
                  href="https://intandem.paperform.co/"
                  className="inviteCodeRadialBtn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Invite Your Network
                </a>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default VipInviteCode;
