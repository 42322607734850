import { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Context/UserContext";
import { handlePostAPI } from "../hooks/handlePostAPI";
import { dotLoader } from "../util/loader";
import { errorToast, successToast } from "../util/toasts";
import mainlogo from "../images/intandem.png";

const Homepage = () => {
  const { setLocalName, setLocalUserRole, setLocalCode, setLocalJoinEmail } =
    useContext(UserContext);

  let navigate = useNavigate();

  const { isLoading, setIsLoading } = useContext(UserContext);
  const [isWhiteListLoading, setIsWhiteListLoading] = useState(false);
  const [code, setCode] = useState("");
  const [whiteListEmail, setWhiteListEmail] = useState("");

  //waitList api
  const handleWhiteLIst = async (whiteListEmail) => {
    setIsWhiteListLoading(true);
    if (!whiteListEmail) {
      setIsWhiteListLoading(false);
      return errorToast("Please input your email");
    }
    const res = await handlePostAPI("/add-to-waitlist/", {
      email: whiteListEmail,
    });
    if (res.email === whiteListEmail) {
      console.log("Success");
      setWhiteListEmail("");
      successToast("Your request has been submitted");
    } else {
      console.error("Warning");
      setWhiteListEmail(res.email || "Invalid Email");
    }
    setIsWhiteListLoading(false);
  };

  // Verify Code
  const verifyCode = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!code) {
      setIsLoading(false);
      return errorToast("Please input your code");
    }
    const res = await handlePostAPI("/verify/", {
      code: code.trim(),
    });
    if (res.error) {
      console.log("Invalid Code", res);
      setCode(res.error || "Invalid Code");
      errorToast("Please enter a valid code");
    } else {
      setLocalCode(code.trim());
      setLocalName(res?.name || "");
      setLocalUserRole(res?.user_type);
      setLocalJoinEmail(res?.email || "");
      navigate("/welcome");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // Run once
    window.localStorage.clear();
  }, []);

  return (
    <section className="homepage-hero">
      <div className="signup-wrp ">
        <div className="vipPage1-grad"></div>
        <div className="grad1"></div>
        <div className="container">
          <div>
            <img className="mainlogo" src={mainlogo} alt="Intandem Dao" />
          </div>

          <h2 className="mt-3 ">
            Devoted to the <span>growth and success</span>
            <br /> of minority owned startups.
          </h2>

          <div className="code-frm">
            <form>
              <h3>ENTER YOUR CODE</h3>
              <div className="formBox">
                <input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter your code"
                  className="form-control text-center input-field"
                  field
                />
                <span>CODE INVALID!</span>
              </div>

              <button onClick={(e) => verifyCode(e)} className="btn1">
                {isLoading ? dotLoader() : "ENTER"}
              </button>
            </form>
          </div>
        </div>

        <div className="rightbarfix">
          <ul>
            <li>
              <a href="/" target="_blank">
                PROGRAMMING
              </a>
            </li>
            <li>
              <a href="/" target="_blank">
                MENTORSHIP
              </a>
            </li>
            <li>
              <a href="/" target="_blank">
                COMMUNITY
              </a>
            </li>
          </ul>
        </div>
        <img src="images/banner-circle.png" alt="" className="signup-circle" />
      </div>

      <div className="invite-code">
        <div className="container">
          <h2>NO INVITE CODE? JOIN THE WAITLIST</h2>

          <div className="code-frm">
            <div>
              <div className="formBox">
                <input
                  type="text"
                  value={whiteListEmail}
                  placeholder="Enter your email"
                  onChange={(e) => setWhiteListEmail(e.target.value)}
                  className="form-control text-center"
                />
                <span>INVALID EMAIL !</span>
              </div>
              <button
                className="btn2"
                type="button"
                onClick={() => handleWhiteLIst(whiteListEmail)}
              >
                {isWhiteListLoading ? dotLoader() : "JOIN THE WAITLIST"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Homepage;
