/* eslint-disable no-undef */
import { useContext, useState } from "react";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Button } from "react-bootstrap";
import { handlePostAPI } from "../hooks/handlePostAPI";
import { UserContext } from "../Context/UserContext";
import { useNavigate } from "react-router-dom";
import { cleanObjects } from "../util/util";

import { errorToast, successToast } from "../util/toasts";
import INTAN_DAO_PASS from "../images/INTAN_DAO_PASS.png";

const CARD_NUMBER_ELEMENT_OPTIONS = {
  placeholder: "CARD NUMBER",
  style: {
    base: {
      color: "black",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      letterSpacing: "0.12em",
      fontSmoothing: "antialiased",
      fontSize: "12px",
      lineHeight: "18px",
      "::placeholder": {
        color: "black",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
const CARD_EXPIRY_ELEMENT_OPTIONS = {
  placeholder: "EXPIRY DATE",
  style: {
    base: {
      color: "black",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "12px",
      "::placeholder": {
        color: "black",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
const CARD_CVC_ELEMENT_OPTIONS = {
  placeholder: "CVV",
  style: {
    base: {
      color: "black",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "12px",
      "::placeholder": {
        color: "black",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function PayWithCard(props) {
  const { localCode, localJoinEmail } = useContext(UserContext);

  let navigate = useNavigate();

  const elements = useElements();
  const stripe = useStripe();

  const [error, setError] = useState("");
  const [loader_flag, setLoaderFlag] = useState(false);

  // This loading flag is used for disabling the pay with card button
  // until the previous request is completed
  const [loading_flag, setLoadingFlag] = useState(false);

  const amount = "25000";

  const handleOnChange = (e) => {
    setError("");
  };

  const handleSubmit = async (e) => {
    setLoadingFlag(true);
    setError("");
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const card_element = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card_element);

    setLoaderFlag(true);
    if (result.error || result.status >= 400) {
      console.log(result.error.message);
      setError(result.error.message);
      setLoaderFlag(false);
      setLoadingFlag(false);
    } else {
      console.log("result.token", result.token);
      setLoaderFlag(false);
      setLoadingFlag(false);
      handlePayment(result.token);
    }
  };

  const handlePayment = async (token) => {
    const apiParameter = {
      id: token,
      amount,
      token: token && token.id,
      email: localJoinEmail,
      login_code: localCode,
    };
    console.log("payload", apiParameter);
    try {
      setLoaderFlag(true);
      const res = await handlePostAPI("/charge/", cleanObjects(apiParameter));
      console.log("Stripe Response--->", res);
      if (res.status === "success") {
        successToast("Payment Successfully paid");
        navigate("/payment-confirmation-check", {
          state: {
            stripe_payment_check: "success",
          },
        });
      } else {
        throw new Error("Payment Failed");
      }
    } catch (err) {
      setLoaderFlag(false);
      setError("error is here");
      console.log("response_backend_error", err);
      navigate("/mint-page", {
        state: {
          stripe_payment_check: "failure",
        },
      });
      errorToast("Your Payment Failed. Please try again");
    }
  };

  return (
    <div>
      {loader_flag ? (
        <div className="cover-full-dom">
          <div className="spinner-border " role="status"></div>
        </div>
      ) : (
        <>
          <div className="container inner-wizard-box">
            <div className="grad"></div>
            <div className="grad1"></div>
            <div className="infoBox mintBoxforPaymentCard">
              <div className="row">
                <div className="col-12 col-md-4 mt-1 offset-md-1">
                  <img
                    className="w-75"
                    alt="Intandem NFT"
                    src={INTAN_DAO_PASS}
                  />
                </div>
                <div className="col-12 col-md-4 d-flex justify-center align-items-center">
                  <div className="w-100">
                    <h2 className="mb-3 mt-3">
                      MINT YOUR NFT WITH
                      <br />
                      <span>Credit Card!!</span>
                    </h2>
                    <p>
                      As a beta community member you will receive a substantial
                      discount on your entry NFT for <span>$250.</span> You can
                      mint with a web3 wallet or your credit card!
                    </p>
                    <div className="col-12 ">
                      <form onSubmit={handleSubmit} className="w-100">
                        <div
                          style={{
                            padding: "14px",
                            border: "3px solid black",
                          }}
                          controlid="formBasicCardNumber"
                        >
                          <CardNumberElement
                            //   ref={setRef}
                            onChange={handleOnChange}
                            options={CARD_NUMBER_ELEMENT_OPTIONS}
                          />
                        </div>

                        <div className="d-md-flex justify-content-between">
                          <div
                            controlid="formBasicExpDate"
                            className="CardElement"
                          >
                            <CardExpiryElement
                              options={CARD_EXPIRY_ELEMENT_OPTIONS}
                              onChange={handleOnChange}
                            />
                          </div>

                          <div controlid="formBasicCCV" className="CardElement">
                            <CardCvcElement
                              options={CARD_CVC_ELEMENT_OPTIONS}
                              onChange={handleOnChange}
                            />
                          </div>
                        </div>
                        <div className="text-md-end">
                          <Button
                            className="radial-btn mt-4"
                            type="submit"
                            disabled={loading_flag}
                          >
                            {`PAY NOW $250`}
                          </Button>
                        </div>
                        <p className="mt-2">{error}</p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PayWithCard;
