import "./App.css";
import "./slick.css";
import "./responsive.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Components/Homepage";
import Welcome from "./Components/Welcome";
import GetStarted from "./Components/GetStarted";
import PreferredEmail from "./Components/PreferredEmail";
import UserPlug from "./Components/UserPlug";
import UserChoice from "./Components/UserChoice";
import MintPage from "./Components/MintPage";
import VipInviteCode from "./Components/VipInviteCode";
import SupporterThankyou from "./Components/SupporterThankyou";
import ContextProvider from "./Context/UserContext";
import Failure from "./Components/Failure";
import PrivateRoute from "./Components/PrivateRoute";
import { Toaster } from "react-hot-toast";
import StripeIntegration from "./Stripe/StripeIntegration";
import PaymentConfirmationCheck from "./Components/PaymentConfirmationCheck";

function App() {
  return (
    <ContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route element={<PrivateRoute />}>
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/get-started" element={<GetStarted />} />
            <Route path="/preferred-email" element={<PreferredEmail />} />
            <Route
              path="/user-plugging/:status/:successCode"
              element={<UserPlug />}
            />
            <Route path="/user-choice" element={<UserChoice />} />
            <Route path="/mint-page" element={<MintPage />} />
            <Route path="/invite-code" element={<VipInviteCode />} />
            <Route path="/supporter-complete" element={<SupporterThankyou />} />
          </Route>
          <Route path="/failure" element={<Failure />} />
          <Route path="/payment" element={<StripeIntegration />} />
          <Route
            path="/payment-confirmation-check"
            element={<PaymentConfirmationCheck />}
          />
          <Route path="/*" element={<Failure />} />
        </Routes>
        <Toaster position="top-right" />
      </BrowserRouter>
    </ContextProvider>
  );
}

export default App;
