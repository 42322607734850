// ETH
// export const contractAddress = "0xaAC4caf561e6Ea8018604D7104d34244F55DdF39";
// Polygon
export const contractAddress = "0x76Aa6e6160F3a0256f1Fbb66Cc2612123F3A5579";

const INFURA_KEY = "c570a46a30ef4c06b7e2261cf31cff13";

export const infura_RPC_URL_ETH_Mainnet = `https://mainnet.infura.io/v3/${INFURA_KEY}`;
export const infura_RPC_URL_ETH_Goerli = `https://goerli.infura.io/v3/${INFURA_KEY}`;

export const infura_RPC_URL_Polygon_Mainnet = `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`;
export const infura_RPC_URL_Polygon_Mumbai = `https://polygon-mumbai.infura.io/v3/${INFURA_KEY}`;

export const MAIN_NETWORK = 80001;

export const network_id = {
  mumbai_polygon: 0x13881,
  mainnet_polygon: 0x89,
  rinkeby_eth: 0x4,
  mainnet_eth: 0x1,
};

// export const NFT_MINT_PRICE = 200000000000000000;
export const NFT_MINT_PRICE = 275000000000000000;

export const networkParams = {
  //   Mumbai Net
  "0x13881": {
    chainId: "0x13881",
    rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
    chainName: "Mumbai Polygon",
    nativeCurrency: { name: "MATIC", decimals: 18, symbol: "MATIC" },
    blockExplorerUrl: ["https://matic-mumbai.chainstacklabs.com"],
    iconUrls: [
      "https://cdn.iconscout.com/icon/free/png-256/matic-2709185-2249231.png",
    ],
  },
  //   Polygon Main Net
  "0x89": {
    chainId: "0x89",
    rpcUrls: ["https://polygon-rpc.com/"],
    chainName: "Polygon Mainnet",
    nativeCurrency: { name: "MATIC", decimals: 18, symbol: "MATIC" },
    blockExplorerUrl: ["https://polygon-rpc.com/"],
    iconUrls: [
      "https://pbs.twimg.com/profile_images/1366339686432579587/THNz1DZm_400x400.png",
    ],
  },
};
