import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import CheckoutForm from "./checkoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_ID);

const StripeIntegration = () => (
  <div>
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  </div>
);

export default StripeIntegration;
