import { API_BASE_URL } from "../api_routes";

export async function handlePostAPI(url, payload) {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  try {
    const headers = myHeaders;
    const res = await fetch(API_BASE_URL + url, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    });
    const response = await res.json();
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}
